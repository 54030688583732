<template>
    <div>
        <v-form ref="form">
            <v-data-table
                :headers="respostaHeaders"
                :items="respostasItens"
                class="elevation-1"
            >
                <template v-slot:item.st_resposta="{ item }">
                    {{ item.st_resposta ? 'Sim' : 'Não' }}                    
                </template>

                <template v-slot:item.valor_impacto="{ item }">
                    <v-currency-field                                            
                        disabled
                        locale="pt-BR"
                        prefix="R$ "
                        v-model="item.valor_impacto"                        
                    />

                </template>
            
                <template v-if="$store.getters['auth/usuarioHasPermissao'](['RespostaVisualizar'])" v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip}">                        
                            <v-btn
                                fab
                                color="cyan"
                                x-small
                                elevation="0"
                                :to="{name: routePrefix + 'resposta-visualizar', params: {resposta: item.id_resposta, pergunta: item.id_pergunta }}"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Visualizar</span>
                    </v-tooltip>
                    <v-tooltip v-if="podeEditar(item)" top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn
                                fab
                                color="success"
                                x-small
                                elevation="0"
                                :to="{name: routePrefix + 'resposta-editar', params: {resposta: item.id_resposta, pergunta: item.id_pergunta }}"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    {{ editIcon }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ editTooltip }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="showEditSEI(item)" top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn
                                fab
                                color="success"
                                x-small
                                elevation="0"
                                :to="{name: routePrefix + 'resposta-editar', params: {resposta: item.id_resposta, pergunta: item.id_pergunta }}"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-alpha-s-box
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Adicionar informações do SEI</span>
                    </v-tooltip>
                    <v-tooltip v-if="showPDF(item)" top>
                        <template v-slot:activator="{on: tooltip}">
                            <v-btn
                                fab
                                color="error"
                                x-small
                                elevation="0"
                                @click="callPDF()"                                
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-file-pdf-box
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar como PDF</span>
                    </v-tooltip>

                    <RespostaPDF v-if="callPF" :resposta="item"/>
                    
                    <DeleteAction v-if="podeDeletar(item)" @delete="deletar(item.id_resposta)"/>
                </template>
            </v-data-table>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn v-if="enableNovaResposta()"
                    class="mx-2 mt-4"
                    color="primary"
                    @click="novaResposta()"
                >
                    Nova Resposta
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import DeleteAction from "../../components/inputs/DeleteAction.vue";
import RespostaPDF from '../../views/pdf/RespostaPDF.vue'
import { createResposta, deleteResposta } from "../../api/resposta";

export default {
    name: "Resposta",
    mixins: [],
    components: { DeleteAction, RespostaPDF },
    props: {       
        questionario: {
            type: Object,
        },
        pergunta: {
            type: Object,            
        },
    },
    data() {
        return {
            form: {
                id_questionario: null,
                id_pergunta: null,
                st_resposta: true,
                id_resposta_status: 1,
                st_impacta_orcamento: false,
            },
            editIcon: null,
            callPF: false,
            editTooltip: null,
            respostasItens: [],
            visualizar: null,
            respostaHeaders: [
                { text: 'Sim/Não', value: 'st_resposta', sortable: false, show: true },
                { text: 'Descrição', value: 'ds_descricao', sortable: false, show: true },
                { text: 'Situação do Regime', value: 'situacao_regime.nm_situacao_regime', sortable: false, show: true },
                { text: 'Último Despacho Estadual', value: 'ultimoDespachoEstadual.ds_despacho', sortable: false, show: this.despachoShow() },
                { text: 'Último Despacho Federal', value: 'ultimoDespachoFederal.ds_despacho', sortable: false, show: this.despachoShow() },
                { text: 'Valor do Impacto', value: 'valor_impacto', sortable: false, show: true },
                { text: 'Status', value: 'status.nm_resposta_status', sortable: false, show: true },
                { text: 'Ações', value: 'actions', sortable: false, show: true, width: '135px' },
            ]
        }
    },
    created() {
        this.visualizar = this.$route.name == 'questionario-visualizar' ? true : false;

        
        if (this.$route.name == 'questionario-visualizar') {
            this.visualizar = true;
            this.routePrefix = 'qv-';
        } else if (this.$route.name == 'questionario-editar') {        
            this.routePrefix = 'qe-';
        }

        this.respostasItens = this.pergunta.respostas;
        this.respostasItens.forEach(resposta => {

            let valorImpacto = 0;

            if (resposta.st_impacta_orcamento) {
                resposta.acrescimos_orcamentarios.forEach(element => {
    
                    if (isNaN(+element.vl_acrescimo)) { // n eh float
                        element.vl_acrescimo = element.vl_acrescimo.replace(',', '.');                    
                    }
    
                    element.vl_acrescimo = parseFloat(element.vl_acrescimo);
                    valorImpacto += element.vl_acrescimo;
                });
                
            }
            valorImpacto = Math.round(valorImpacto * 100) / 100;
            resposta.valor_impacto = valorImpacto;

            let ultimoDespachoFederal = resposta.despachos.filter(despacho => despacho.tp_despacho == 'F').pop();
            let ultimoDespachoEstadual = resposta.despachos.filter(despacho => despacho.tp_despacho == 'E').pop();
    
            resposta.despachos.forEach(despacho => {
                if (despacho.tp_despacho == 'F' && ultimoDespachoFederal.created_at < despacho.created_at) {
                    ultimoDespachoFederal = despacho;
                }
    
                if (despacho.tp_despacho == 'E' && ultimoDespachoEstadual.created_at < despacho.created_at) {
                    ultimoDespachoEstadual = despacho;
                }
            });

            resposta.ultimoDespachoFederal = ultimoDespachoFederal;
            resposta.ultimoDespachoEstadual = ultimoDespachoEstadual;            
            
        });

        this.respostaHeaders = this.respostaHeaders.filter(x=>x.show);
    },
    methods: {        
        deletar(id_resposta) {           
            
            if (this.respostasItens.length == 1) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Erro ao excluir a resposta.',
                    mensagem: 'Não é possível excluir a última resposta. Entretanto, é possível editá-la para informar outros valores.',
                });

                this.$vuetify.goTo(0);
            } else {

                deleteResposta(id_resposta).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    });

                    this.respostasItens = this.respostasItens.filter(function( obj ) {
                        return obj.id_resposta !== id_resposta;
                    });

                    this.$vuetify.goTo(0);
                });
            }
        },
        novaResposta() {

            let respostaNaoBoolean = false;
            this.respostasItens.forEach(resposta => {
                if (resposta.st_resposta == false) {
                    respostaNaoBoolean = true;
                }
            });

            if (respostaNaoBoolean) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Warning',
                    titulo: 'Erro ao criar nova resposta.',
                    mensagem: 'Não é possível criar mais uma resposta, pois já existe uma resposta NÃO. Entretanto, é possível editá-la para informar outros valores.',
                });

                this.$vuetify.goTo(0);
            } else {

                this.form.id_questionario = this.questionario.id_questionario;
                this.form.id_pergunta = this.pergunta.id_pergunta;

                let promise;
                promise = createResposta(this.form)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.$router.push({ name: 'qe-resposta-editar', params: { pergunta: this.pergunta.id_pergunta, resposta: response.data.data.id_resposta } });

                    //this.goBack();
                }).catch(error => this.handleError(error))

            }
        },
        handleError(error) {
            
            if(error.response.status == 422) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Ocorreu uma falha no upload do arquivo de despacho.',
                    mensagem: 'O arquivo é de um tipo inválido ou excede o tamanho máximo permitido.',
                });
            } else {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: error.response.data.error.message.title,
                    mensagem: error.response.data.error.message.msg,
                });
            }

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});                
            }
        },
        podeDeletar(item) {
            if (this.visualizar) {
                return false;
            }

            // se
                // Cadastrador
                // Permissão de Exclusão
                // Resposta estiver "Aberta"
                // Questionário "Em Preenchimento"            
                if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaDeletar'])
                && item.status.nm_resposta_status == 'Aberta'
                && ( this.questionario.status.nm_questionario_status == 'Em Preenchimento' || this.questionario.status.nm_questionario_status == 'Devolvido para Ajustes' ) ) {
                
                return true;
            }
        },
        podeEditar(item) {
            if (this.visualizar) {
                return false;
            }
            
            // se
                // Cadastrador
                // Permissão de atualização
                // Resposta estiver "Aberta"
                // Questionário "Em Preenchimento"            
            if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && item.status.nm_resposta_status == 'Aberta'
                && this.questionario.status.nm_questionario_status == 'Em Preenchimento') {
                
                this.editIcon = 'mdi-pencil';
                this.editTooltip = 'Preencher Resposta';
                return true;
            }

            // se
                // Cadastrador
                // Permissão de atualização
                // Resposta estiver "Em Ajuste"
                // Questionário "Em Análise & Ajustes"            
            if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && item.status.nm_resposta_status == 'Em Ajuste'
                && this.questionario.status.nm_questionario_status == 'Em Análise & Ajustes') {
                
                this.editIcon = 'mdi-pencil';
                this.editTooltip = 'Ajustar Resposta';
                return true;
            }

            // se
                // Cadastrador
                // Permissão de atualização
                // Resposta estiver "Em Análise"
                // Questionário "Devolvido para Ajustes"            
                if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && item.status.nm_resposta_status == 'Em Análise'
                && this.questionario.status.nm_questionario_status == 'Devolvido para Ajustes') {
                
                this.editIcon = 'mdi-pencil';
                this.editTooltip = 'Ajustar Resposta';
                return true;
            }
            
            // se
                // Analista
                // Permissão de atualização
                // Resposta estiver "Em análise"
                // Questionário "Em análise"
            if (this.$store.getters['auth/usuarioHasRole'](['Analista'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && item.status.nm_resposta_status == 'Em Análise'
                && (this.questionario.status.nm_questionario_status == 'Em Análise' || this.questionario.status.nm_questionario_status == 'Em Análise & Ajustes')) {
                
                this.editIcon = 'mdi-text-box-edit';
                this.editTooltip = 'Analisar Resposta';
                return true;
            }

            return false;
        },
        despachoShow() {
            // se for analista
            return this.$store.getters['auth/usuarioHasRole'](['Analista']);
        },

        showEditSEI(item) {            
            // se for analista e o questionário estiver Encerrado e tiver permissão de atualização
            return !this.visualizar
                && this.$store.getters['auth/usuarioHasRole'](['Analista'])
                && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAtualizar'])
                && item.status.nm_resposta_status == 'Validada'
                && item.st_resposta
                && this.questionario.status.nm_questionario_status == 'Encerrado';
        },
        showPDF(item) {
            // se
            // Analista Federal
            // Resposta estiver "Validada"
            // Questionário "Encerrado"
            return item.status.nm_resposta_status == 'Validada'
                && this.questionario.status.nm_questionario_status == 'Encerrado'
                && item.st_resposta;
        },
        callPDF() {
            this.callPF = true;
        },
        enableNovaResposta() {
            return !this.visualizar
            && this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
            && this.$store.getters['auth/usuarioHasPermissao'](['RespostaCriar'])
            && (this.questionario.status.nm_questionario_status == 'Em Preenchimento' || this.questionario.status.nm_questionario_status == 'Devolvido para Ajustes')
        }
    },
}
</script>
