<script>
import {defineComponent} from "vue";
import {getConselhos} from "../../api/conselho";
import LineChart from "../../components/charts/LineChart.vue";
import MonthPickerInput from "../../components/inputs/MonthPickerInput.vue";
import * as apexcharts from "vue-apexcharts";
import {getQuestionarioReport} from "../../api/relatorios/questionario_chart";
import {validationMixin} from "../../mixins/validationMixin";

export default defineComponent({
    components: {MonthPickerInput, LineChart, apexchart: apexcharts},
    mixins: [validationMixin],
    data() {
        return {
            headers: [
                {text: 'Órgão', value: 'nm_orgao'},
                {text: 'Sigla', value: 'sg_sigla'},
            ],
            orgaos: [],
            filtro: {
                id_conselho: null,
                month_date: null,
            },
            conselhosSelect: [],
            series: [
                {
                    name: 'Pendentes',
                    color: '#FF4560',
                    data: []
                },
                {
                    name: 'Enviados',
                    color: '#2ecc71',
                    data: []
                }],
            chartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Questionários Pendentes X Enviados',
                    align: 'center'
                },
                xaxis: {
                    categories: [],
                }
            }
        }
    },
    created() {
        getConselhos().then((response) => {
            this.conselhosSelect = response.data.data;
        });
    },
    methods: {
        submitForm() {
            if (this.$refs.form.validate()) {
                getQuestionarioReport(this.filtro.id_conselho, this.filtro.month_date).then((response) => {
                    const data = response.data.data;
                    this.orgaos = data.orgaos_pendentes;
                    this.series = [
                        {
                            name: 'Pendentes',
                            color: '#FF4560',
                            data: data.pendentes
                        },
                        {
                            name: 'Enviados',
                            color: '#2ecc71',
                            data: data.enviados
                        }
                    ]
                    this.chartOptions = {
                        xaxis: {
                            categories: data.dias
                        },
                        annotations: {
                            xaxis: [{
                                x: data.dia_final_questionario + '/' + this.filtro.month_date?.split("/")[0],
                                strokeDashArray: 1,
                                borderColor: '#d05d5d',
                                label: {
                                    borderColor: '#FF4560',
                                    style: {
                                        color: '#fff',
                                        fontSize: '12pt',
                                        background: '#FF4560',
                                    },
                                    text: 'Prazo Final',
                                    orientation: 'horizontal'
                                }
                            }]
                        },
                        title: {
                            text: 'Questionários Pendentes X Enviados - Total de questionários: ' + data.questionario_total,
                            align: 'center'
                        }
                    }
                });
            }
        }
    }

})
</script>

<template>
    <div>
        <v-card class="mb-9">
            <v-form ref="form">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-select
                                label="Conselho"
                                outlined
                                v-model="filtro.id_conselho"
                                dense
                                clearable
                                :items="conselhosSelect"
                                item-value="id_conselho"
                                item-text="nm_conselho"
                                hide-details="false"
                                :rules="validate('required', 'Sigla do conselho')"
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <MonthPickerInput
                                v-model="filtro.month_date"
                                :rules="validate('required', 'Campo de data é obrigatório')"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="submitForm"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    Pesquisar
                </v-btn>
            </v-card-actions>
        </v-card>
        <div id="chart">
            <apexchart type="line" height="400" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div
            class="my-4"
            v-if="orgaos.length">

            <v-divider></v-divider>
            <div class="my-4 text-h6 d-flex justify-center align-center">Órgãos Pendentes</div>
            <v-data-table
                :headers="headers"
                :items="orgaos"
                class="elevation-1"
            ></v-data-table>
        </div>
    </div>
</template>

