import api from './index';

const createPerguntaQualitativa = (dados) => api.post('pergunta-qualitativa', dados);
const getPerguntaQualitativa = (id_pergunta_qualitativa) => api.get(`pergunta-qualitativa/${id_pergunta_qualitativa}`);
const updatePerguntaQualitativa = (id_pergunta_qualitativa, dados) => api.put(`pergunta-qualitativa/${id_pergunta_qualitativa}`, dados);
const ativarInativarPerguntaQualitativa = (id_pergunta_qualitativa, in_inativo) => api.put(`pergunta-qualitativa/ativar-inativar/${id_pergunta_qualitativa}`, in_inativo);
const getAllPerguntaQualitativa = () => api.get('pergunta-qualitativa/getAllPerguntaQualitativa');

const getPerguntaQualitativaPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('pergunta-qualitativa/pergunta-qualitativa-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {
    getPerguntaQualitativaPaginate,
    createPerguntaQualitativa,
    getPerguntaQualitativa,
    updatePerguntaQualitativa,
    ativarInativarPerguntaQualitativa,
    getAllPerguntaQualitativa
}
